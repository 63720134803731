<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-auto" />
        </colgroup>
      </template>
      <template v-slot:item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template v-slot:item-Bron="{ item }">
        <small v-if="!item.bron" class="text-red-500">Geen bron opgegeven</small>
        <div v-else>{{ item.bron }}</div>
      </template>
      <template v-slot:item-Aantal="{ item }">
        <div>
          {{ item.aantal }}
          <small class="italic text-gray-500" v-if="item.aantal_geannuleerd > 0">(Waarvan {{ item.aantal_geannuleerd }} geannuleerd)</small>
        </div>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import useGetApi from '@/hooks/useGetApi'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { monthToString } from '@/functions/formatDate'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const headers = [
  'Datum',

  'bron', // bron
  'Aantal', // count
]

const { data } = useGetApi('/api/dashboard/stats/ritten/bronnen', props.filters, { watch: true })
</script>
